import React from 'react';
import { List, Datagrid, TextField, Create, Edit, SimpleForm, DisabledInput, TextInput, LongTextInput, EditButton} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CardActions, ShowButton } from 'react-admin';
import PreviewButton from './PreviewButton';

export const FormsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
        </Datagrid>
    </List>
);

export const FormCreate = (props) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput label="ID" source="id" options={{fullWidth: true }} />
                    <TextInput 
                        source="body" 
                        InputProps={{
                            style: {
                                fontSize: "10px",
                                fontFamily: "monospace"
                            }
                        }}
                        options={{ multiline: true, fullWidth: true }}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

const PostEditActions = ({ basePath, data, resource }) => (
    <CardActions>
        <PreviewButton color="primary" record={data} resource={resource} />
    </CardActions>
);

export const FormEdit = (props) => (

    <Edit actions={<PostEditActions />} {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <DisabledInput label="ID" source="id" options={{fullWidth: true }} />
                    <TextInput 
                        source="body" 
                        InputProps={{
                            style: {
                                fontSize: "10px",
                                fontFamily: "monospace",
                                height: "50%"
                            }
                        }}
                        options={{ multiline: true, fullWidth: true, whiteSpace: "nowrap", overflow: "auto" }}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>

);
