import React from 'react';
import { Admin, fetchUtils, Resource, ListGuesser } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
//import authProvider from './authProvider';
import { AuthProvider, Login } from 'ra-cognito';
import Amplify, { Auth } from 'aws-amplify';
import MyLayout from './MyLayout';
import { FramesList } from './frames';
import { FeedsList, FeedEdit, FeedEditIcal, FeedEditGooglecalendar, FeedEditOpenWeatherMap, FeedEditO365calendar } from './feeds';
import { FormsList, FormCreate, FormEdit } from './forms';
import SyncIcon from '@material-ui/icons/Sync';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import CodeIcon from '@material-ui/icons/Code';

Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_ZuvqcZhg3',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '32mp7avrbqe69le4c56viq19a1',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
        
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

// You can get the current config object
const currentConfig = Auth.configure();
const username = Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {console.log(user.username)});

const httpClient = async (url, options = {}) => {

    var token = await Auth.currentAuthenticatedUser().then((user) => {return user.signInUserSession.idToken.jwtToken});
    
    options.user = {
        authenticated: true,
        token: 'Bearer '+token
    }
    return fetchUtils.fetchJson(url, options);
    
}
const dataProvider = simpleRestProvider('https://api.dataframe.io/dev/admin', httpClient);

const App = () => (
    <Admin appLayout={MyLayout} authProvider={AuthProvider} dataProvider={dataProvider} loginPage={Login}>
        
        <Resource name="frames" list={FramesList} icon={AspectRatioIcon} />
        <Resource name="feeds" list={FeedsList} icon={SyncIcon} />
        <Resource name="feeds_ical" edit={FeedEditIcal}/>
        <Resource name="feeds_googlecalendar" edit={FeedEditGooglecalendar}/>
        <Resource name="feeds_o365calendar" edit={FeedEditO365calendar}/><Resource name="o365calendars"/>
        <Resource name="feeds_openweathermap" edit={FeedEditOpenWeatherMap}/>
        <Resource name="forms" list={FormsList} icon={CodeIcon} create={FormCreate} edit={FormEdit}/>

    </Admin>
);

export default App;