import React from 'react';
import { Edit, DisabledInput, List, Datagrid, TextField, ShowController, SimpleForm, Create, TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import O365ConsentButton from './O365ConsentButton';
import { CardActions} from 'react-admin';
import { ReferenceInput, SelectInput } from 'react-admin';
import { parse } from "query-string";
import { FormDataConsumer } from 'react-admin';

const feedRowClick = (id, basePath, record) => {
    console.log(record);
    console.log(record.type);
    if (record.type=='ical') {return '/feeds_ical/'+record.id};
    if (record.type=='googlecalendar') {return '/feeds_googlecalendar/'+record.id};
    if (record.type=='o365calendar') {return '/feeds_o365calendar/'+record.id};
    return 'edit'
}

export const FeedsList = props => (
    <List {...props}>
        <Datagrid rowClick={feedRowClick}>
            <TextField source="type" />
            <TextField source="label" />
        </Datagrid>
    </List>
);


export const FeedEditIcal = (props) => (

    <Edit {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput label="Label" source="id" options={{fullWidth: true }} />
                    <TextInput label="URL" source="url" options={{fullWidth: true }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>

);

export const FeedEditGooglecalendar = (props) => (
    
    <Edit {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput label="Label" source="id" options={{fullWidth: true }} />
                    <TextInput label="Google Calendar ID" source="calendarId" options={{fullWidth: true }} />
                    <TextInput label="OAuth Refresh Token" source="RefreshKey" options={{fullWidth: true }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>

);

const PostEditActionsO365calendar = ({ basePath, data, resource }) => (
    <CardActions>
        <O365ConsentButton color="primary" record={data} resource={resource} />
    </CardActions>
);

export const FeedEditO365calendar =  (props) => {
    
    // https://marmelab.com/blog/2018/07/09/react-admin-tutorials-form-for-related-records.html
    const { tenant: tenant_string } = parse(props.location.search);
    //const tenantid = props.reduxstore.form["record-form"].values.tenant;
    const tenantid = "zwei";

    return (
    <Edit actions={<PostEditActionsO365calendar />} {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput label="Label" source="id" options={{fullWidth: true }} />
                    <TextInput label="Office365 Tenant ID" source="tenant" options={{fullWidth: true }} defaultValue={tenant_string} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                        <ReferenceInput label="Office365 Calendar ID" source="o365calenderid" reference="o365calendars" filter={{ tenant: formData.tenant }} >
                            <SelectInput optionText="title" />
                        </ReferenceInput>
                        } 
                    </FormDataConsumer>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
    )
};

export const FeedEditOpenWeatherMap = (props) => (

    <Edit {...props}>
        <SimpleForm submitOnEnter={false}>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput label="Label" source="id" options={{fullWidth: true }} />
                    <TextInput label="City ID" source="calendarId" options={{fullWidth: true }} />
                    <TextInput label="City Name" source="RefreshKey" options={{fullWidth: true }} />
                    <TextInput label="City Country" source="RefreshKey" options={{fullWidth: true }} />
                    <TextInput label="City Long" source="coord.long" options={{fullWidth: true }} />
                    <TextInput label="City Lat" source="coord.lat" options={{fullWidth: true }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>

);