import React, { Component } from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import Amplify, { Auth } from 'aws-amplify';

export default class MyUserMenu extends Component {

    constructor() {
        super();
        this.state = {
          username: []
        };
      }

    componentDidMount() {
        Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
            console.log(user.username)
            this.setState({
                username: user.username
              });
        })
    }

    render() {
        return (
            <UserMenu label={this.state.username ? this.state.username : 'profile'}>
                <MenuItemLink
                    to="/configuration"
                    primaryText="Configuration"
                    leftIcon={<SettingsIcon />}
                />
            </UserMenu>
        );
    }
}
