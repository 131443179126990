import React from 'react';
import { List, Datagrid, TextField, ImageField } from 'react-admin';

export const FramesList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="template" />
            <TextField source="tenant" />
            <ImageField source="current" title="title" />
        </Datagrid>
    </List>
);