import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import Amplify, { Auth } from 'aws-amplify';

class PreviewButton extends Component {
    constructor(props) {
        super(props);
        this.state = {previewwindow: null};
    }

    handleClick = async () => {

        //const {record} = this.props;
        const pugbody = this.props.reduxstore.form["record-form"].values.body;

        //console.log(pugbody);
        
        var token = await Auth.currentAuthenticatedUser().then((user) => {return user.signInUserSession.idToken.jwtToken});
        var tenant = await Auth.currentAuthenticatedUser().then((user) => {return user.username});

        const response = await fetch('https://0g1mcsnfkl.execute-api.us-east-1.amazonaws.com/dev/admin/previewform?tenant='+tenant, {
			method: 'POST',
			body: JSON.stringify({
				pug: pugbody
			}),
			headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer "+token
			}
        });
        
        const body = await response.text();
        
        //console.log(body);
        //console.log(this.state);
        
        var w;
        if (this.state.hasOwnProperty('previewwindow') && this.state.previewwindow!=null && this.state.previewwindow.parent!=null) {
            w = this.state.previewwindow;
            w.document.body.innerHTML = "";
        } else {
            w = window.open("about:blank", "_blank", "location=0");
            this.setState({"previewwindow": w});
        }

        w.document.write(body);

    }

    render() {
        return <Button label="Preview" onClick={this.handleClick} >PREVIEW</Button>;
    }
}

PreviewButton.propTypes = {
    record: PropTypes.object,
    pugbody: PropTypes.object
};

const mapStateToProps = state => ({
    reduxstore: state
});
export default connect(mapStateToProps)(PreviewButton);

//export default connect(null, {})(PreviewButton);

