import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import Amplify, { Auth } from 'aws-amplify';

class O365ConsentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {previewwindow: null};
    }

    handleClick = async () => {
        const feedid = this.props.reduxstore.form["record-form"].values.id
        //var tenant = await Auth.currentAuthenticatedUser().then((user) => {return user.username});

        //window.open("https://login.microsoftonline.com/common/adminconsent?client_id=474b58d5-88ab-4fe1-9910-2e0b19bf0453&redirect_uri="+encodeURI("https://manager.dataframe.io/o365redirect.html")+"&state="+encodeURI(window.location.href), "_blank", "location=0");
        //window.alert(window.location.href);
        //window.alert(btoa(window.location.href));
        window.location.href="https://login.microsoftonline.com/common/adminconsent?client_id=474b58d5-88ab-4fe1-9910-2e0b19bf0453&redirect_uri="+encodeURI("https://manager.dataframe.io/o365redirect.html")+"&state="+btoa(window.location.href);
    }
    
    render() {
        return <Button label="Preview" onClick={this.handleClick} >LINK WITH OFFICE 365</Button>;
    }
}

O365ConsentButton.propTypes = {
    record: PropTypes.object,
    pugbody: PropTypes.object
};

const mapStateToProps = state => ({
    reduxstore: state
});
export default connect(mapStateToProps)(O365ConsentButton);
    